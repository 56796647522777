import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/kevin/Documents/GitHub/credit101-react/src/components/blog-post-layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <figcaption>College Students (Photo by Amariotti/Wikipedia)</figcaption>
    <p>{`Instead of writing about "the best travel cards" or "how to build credit in 30 days" in our first article, we decided to list the `}<em parentName="p">{`reasons`}</em>{` for building credit. We wrote that good credit can save you a lot of money, land you better jobs, get you phone contracts without much hassle, and `}<a parentName="p" {...{
        "href": "https://credit101.net/build/you-need-good-credit-but-why/"
      }}>{`so much more`}</a>{`. A lot of these things ‒ except for saving money ‒ might seem very distant in the future for a college student. But if you think about this: do you really want to start building credit when your friends are already booking free flights to Paris or Cancún because they got approved for some travel cards with large signup bonuses? It's `}<em parentName="p">{`never`}</em>{` too early to start building credit.`}</p>
    <p>{`When we were curating this list for you, we took two things into consideration. Our top priority is to find the cards you can get approved for (obviously). We also want to find you the right card. That is, finding you a card that fits your credit profile and spending behavior. Additionally, we assumed that you don't already have good to excellent credit (because otherwise, you can get cards with rewards better than most student cards). Each card we recommend is optimized for a common credit profile or spending behavior (e.g. grocery, gas, travel).`}</p>
    <blockquote>
      <p parentName="blockquote">{`Related: `}<a parentName="p" {...{
          "href": "https://credit101.net/score/four-ways-to-check-your-credit-score/"
        }}>{`4 Ways to Check Your Credit Score`}</a></p>
    </blockquote>
    <h2>{`A Bit of Everything`}</h2>
    <p><img parentName="p" {...{
        "src": "https://s21.q4cdn.com/936756523/files/images/news/180815-STUDENT-CARD_BOTH.JPG",
        "alt": "Discover it® Student Chrome Card"
      }}></img></p>
    <figcaption>Discover it® Student Chrome Card (Photo by Discover)</figcaption>
    <p>{`Discover's student cards have been quite popular among college students. We like the all-round Discover it® Student Chrome card in particular. It's super easy to get if you verify your student status with Discover. You'll get access to your monthly updated FICO 8 score ‒ a nice touch that will help you build credit.`}</p>
    <p>{`In terms of rewards, if you have a GPA of 3.0 or above, you can get a $20 statement credit every year through `}<a parentName="p" {...{
        "href": "https://www.discover.com/credit-cards/student/good-grades.html"
      }}>{`Good Grades Reward`}</a>{`. Not sure if $20 is enough to get you incentivized to study harder though. Discover doesn't really offer a signup bonus, but during the first year, Discover will match all of your cash back (i.e. 2x bonus).`}</p>
    <p><strong parentName="p">{`Pros:`}</strong></p>
    <ul>
      <li parentName="ul">{`$20 annual statement credit with a 3.0 GPA`}</li>
      <li parentName="ul">{`Cashback Match gives you double cash back in the first year`}</li>
      <li parentName="ul">{`2% cash back at gas stations and restaurants (up to $1,000 per quarter).`}</li>
      <li parentName="ul">{`1% unlimited cash back`}</li>
      <li parentName="ul">{`Free FICO 8 Score Reporting`}</li>
      <li parentName="ul">{`Custom Card Design`}</li>
      <li parentName="ul">{`Good customer service (you can even `}<a parentName="li" {...{
          "href": "https://www.discover.com/credit-cards/help-center/contact-us/?icmpgn=publichelpcenter"
        }}>{`iMessage them`}</a>{`)`}</li>
      <li parentName="ul">{`No `}<a parentName="li" {...{
          "href": "https://credit101.net/cards/credit-card-glossary/#annual-fee"
        }}>{`Annual Fee`}</a></li>
    </ul>
    <p><strong parentName="p">{`Cons:`}</strong></p>
    <ul>
      <li parentName="ul">{`Discover is not as widely accepted as Visa and Mastercard credit cards.`}</li>
      <li parentName="ul">{`FICO score is not updated weekly`}</li>
      <li parentName="ul">{`The signup bonus exists as Cashback Match`}</li>
      <li parentName="ul">{`Payment can be confusing and can't pay upfront`}</li>
    </ul>
    <div className="px-8">
  <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"></script>
  <ins className="adsbygoogle" style={{
        "display": "block"
      }} data-ad-client="ca-pub-3524456596508824" data-ad-slot="5342728383" data-ad-format="auto" data-full-width-responsive="true">
  </ins>
  <script dangerouslySetInnerHTML={{
        __html: "(adsbygoogle = window.adsbygoogle || []).push({});"
      }}></script>
    </div>
    <h2>{`Best for Unlimited Cash Back`}</h2>
    <p><img parentName="p" {...{
        "src": "https://online.citi.com/JRS/banners/modules/HP4382_DC_Module.jpg",
        "alt": "Citi® Double Cash Card"
      }}></img></p>
    <figcaption>Citi® Double Cash Card (Photo by Citi)</figcaption>
    <p>{`The Citi® Double Cash card is a very interesting card. It's not listed as a student card but many students were able to acquire it pretty easily. The Double Cash card is industry-leading in terms of unlimited cash back, offering 1% back when you spend and 1% back when you pay. The overall 2% cash back rate is unmatched even by the Freedom Unlimited® card from Chase. Though, the Freedom Unlimited® is still a viable alternative, offering 3% back on the first $20,000 spent during the first year. Overall, Double Cash is a pretty good card for the unlimited cash back, though it doesn't have many other benefits.`}</p>
    <p><strong parentName="p">{`Pros:`}</strong></p>
    <ul>
      <li parentName="ul">{`Industry-leading 2% cash back`}</li>
      <li parentName="ul">{`No Annual Fee`}</li>
    </ul>
    <p><strong parentName="p">{`Cons:`}</strong></p>
    <ul>
      <li parentName="ul">{`3% Foreign Transaction Fee`}</li>
      <li parentName="ul">{`Not a whole lot of perks other than the 2% back`}</li>
      <li parentName="ul">{`Doesn't offer credit monitoring which is standard in the industry`}</li>
    </ul>
    <div className="px-8">
  <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"></script>
  <ins className="adsbygoogle" style={{
        "display": "block",
        "textAlign": "center"
      }} data-ad-layout="in-article" data-ad-format="fluid" data-ad-client="ca-pub-3524456596508824" data-ad-slot="9281973399">
  </ins>
  <script dangerouslySetInnerHTML={{
        __html: "(adsbygoogle = window.adsbygoogle || []).push({});"
      }}></script>
    </div>
    <h2>{`Best for Gas & Grocery Shopping`}</h2>
    <p><img parentName="p" {...{
        "src": "https://static1.businessinsider.com/image/5c058136d78fe0500328a8ff-1300/american-express-blue-cash-preferred-card-1.jpg",
        "alt": "Blue Cash Everyday® (Photo by Holly Johnson/Business Insider)"
      }}></img></p>
    <figcaption>Blue Cash Everyday® (Photo by Holly Johnson/Business Insider)</figcaption>
    <p>{`The Blue Cash Everyday® (commonly referred to as BCE) from American Express is not exactly a student card, but it is `}<strong parentName="p">{`insanely beginner-friendly`}</strong>{`. Amex is trying really hard to attract young adults and new customers, which explains why BCE isn't hard to get. For example, the author was automatically approved for the BCE with a $1,000 credit line with `}<strong parentName="p">{`no credit history`}</strong>{`.`}</p>
    <p>{`The BCE is an entry level cash back card offering good rewards, most notably its 3% cash back on grocery shopping. BCE's big brother BCE preferred, which is actually the card in our cover picture) is a card with a $95 annual fee but offers 6% back on grocery purchases. We also like its transparent design which is quite flashy. Plus, there's no annual fee. On the other hand, $1,000 is a bit too high for a signup bonus minimum spending requirement if you consider the Chase Freedom® card.`}</p>
    <p><strong parentName="p">{`Pros:`}</strong></p>
    <ul>
      <li parentName="ul">{`3% back on grocery shopping`}</li>
      <li parentName="ul">{`2% back on gas and select department stores`}</li>
      <li parentName="ul">{`Eye-catching transparent design`}</li>
      <li parentName="ul">{`Free FICO Score Reporting`}</li>
      <li parentName="ul">{`No Annual Fee`}</li>
    </ul>
    <p><strong parentName="p">{`Cons:`}</strong></p>
    <ul>
      <li parentName="ul">{`$1,000 minimum spending requirement to get the $100 signup bonus is quite high. For comparison, it's $500 minimum for $150 bonus for Chase Freedom®.`}</li>
      <li parentName="ul">{`Amex is not accepted everywhere, but more and more merchants are accepting it.`}</li>
      <li parentName="ul">{`3% Foreign Transaction Fee`}</li>
    </ul>
    <div className="px-8">
  <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"></script>
  <ins className="adsbygoogle" style={{
        "display": "block",
        "textAlign": "center"
      }} data-ad-layout="in-article" data-ad-format="fluid" data-ad-client="ca-pub-3524456596508824" data-ad-slot="9281973399">
  </ins>
  <script dangerouslySetInnerHTML={{
        __html: "(adsbygoogle = window.adsbygoogle || []).push({});"
      }}></script>
    </div>
    <h2>{`Best for No SSN`}</h2>
    <p><img parentName="p" {...{
        "src": "https://static.deserve.com/credit_application/images/deserve/Deserve-eduapp.jpg",
        "alt": "Deserve Edu® Mastercard"
      }}></img></p>
    <figcaption>Deserve Edu® Mastercard (Photo by Deserve)</figcaption>
    <p>{`Note: If you're an international student with an SSN, you can safely ignore this card and go for the other cards. The reason is that there are countless reports indicating that international students can get approved for student cards pretty easily. In fact, a couple of students got approved for the Chase Freedom® card which actually requires fair to good credit.`}</p>
    <p>{`Deserve® Edu is a great card if you do not have a Social Security number. You get 1% unlimited cash back and free Amazon Prime student membership for the first year (worth $59). It also covers up to $600 if you damage or lose your cellphone, though we're not sure if it's subject to terms (comment below if you've successfully claimed that $600). Something to be aware of: the card is not issued by a well-known issuer and there are occasionally some bad reviews.`}</p>
    <p><strong parentName="p">{`Pros:`}</strong></p>
    <ul>
      <li parentName="ul">{`Free Amazon Prime student membership for the first year (worth $59)`}</li>
      <li parentName="ul">{`No SSN Required`}</li>
      <li parentName="ul">{`1% unlimited cash back`}</li>
      <li parentName="ul">{`Up to $600 cellphone damage and loss protection`}</li>
      <li parentName="ul">{`No Annual Fee`}</li>
    </ul>
    <p><strong parentName="p">{`Cons:`}</strong></p>
    <ul>
      <li parentName="ul">{`Not exactly a well-known card issuer`}</li>
      <li parentName="ul">{`We're unsure how the $600 cellphone insurance works`}</li>
      <li parentName="ul">{`Doesn't offer credit monitoring which is standard in the industry`}</li>
      <li parentName="ul">{`Poor customer service`}</li>
      <li parentName="ul">{`Balance transfer and cash advance are not available (not that you need it frequently)`}</li>
    </ul>
    <div className="px-8">
  <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"></script>
  <ins className="adsbygoogle" style={{
        "display": "block"
      }} data-ad-client="ca-pub-3524456596508824" data-ad-slot="5342728383" data-ad-format="auto" data-full-width-responsive="true">
  </ins>
  <script dangerouslySetInnerHTML={{
        __html: "(adsbygoogle = window.adsbygoogle || []).push({});"
      }}></script>
    </div>
    <h2>{`The Bottom Line`}</h2>
    <p>{`We think that these cards are the best in their categories and are very easy to get approved for as a student. To determine if they fit your spending behavior, you can keep a log of where you spend so that you can optimize your purchases. Thanks for reading and good luck with your applications!`}</p>

    </MDXLayout>;
}
MDXContent.isMDXComponent = true;
      